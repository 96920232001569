import React from 'react';
import { PageProps } from 'gatsby';
import Layout from 'src/layouts/Page';
import HeroSection from 'src/sections/Product/Hero';
import TestimonialsSection from 'src/sections/shared/Testimonials';
import Seo from 'src/components/Seo';
import Revolutionize from 'src/sections/Product/Revolutionize';
import Features from 'src/sections/Product/Features';
import BuiltSection from 'src/sections/Product/Built';
import DashboardSection from 'src/sections/Product/Dashboard';
import ApiWrapper from 'src/components/ApiWrapper';
import { Category } from 'src/businessTypes';

type Props = PageProps<undefined, {
  businessType: string,
  category?: Category,
}>;

const ProductPage: React.FC<Props> = ({ pageContext: { businessType, category } }) => (
  <ApiWrapper apiServices={['home-api']}>
    <Layout>
      <Seo
        title={`${category?.seoEmoji ?? '💻'} ${businessType || 'Business'} Website Design & Software - Bizwise`}
        description={`Bizwise helps bring ${businessType || 'small'} businesses online with a modern website design, hosting, SEO, SSL, and tools including email, invoicing, booking, and more.`}
      />
      <HeroSection businessType={businessType} />
      <DashboardSection businessType={businessType} />
      <BuiltSection businessType={businessType} />
      <Features businessType={businessType} />
      <TestimonialsSection isLight={false} showBackgroundImage={false} />
      <Revolutionize />
    </Layout>
  </ApiWrapper>

);

export default ProductPage;
