import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import {
  Theme, pxToRem, breakpoint, spacing,
} from 'src/theme';
import IconButton from 'src/components/core/IconButton';
import Carousel from 'src/components/Carousel';
import { assetLink } from 'src/utils';
import Typography from 'src/components/core/Typography';
import { StaticImage } from 'gatsby-plugin-image';

const avatarSizePx = 50;
const avatarSize = pxToRem(avatarSizePx);
interface StyleProps {
  isLight: boolean,
}

const useStyles = createUseStyles<string, StyleProps, Theme>((theme) => ({
  quoteContainer: {
    display: 'flex',
    flexDirection: 'row',
    [breakpoint.down('sm')]: {
      flexDirection: 'column',
    },
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  endQuote: {
    [breakpoint.down('sm')]: {
      marginLeft: 'auto !important',
    },
  },
  arrowIcon: ({ isLight }) => ({
    width: pxToRem(50),
    height: pxToRem(50),
    minWidth: pxToRem(50),
    minHeight: pxToRem(50),
    marginTop: 'auto',
    marginBottom: 'auto',
    color: isLight ? theme.palette.primary.TEAL : '#FFFFFF',
    '&:hover': {
      color: isLight ? '#78E2E5' : theme.palette.secondary.GRAY,
    },
  }),
  avatar: {
    marginRight: spacing(8),
    minHeight: avatarSize,
    height: avatarSize,
    minWidth: avatarSize,
    width: avatarSize,
    borderRadius: '50%',
    // Safari fix
    '& *': {
      borderRadius: '50%',
    },
  },
}));

export interface Testimonial {
  name: string,
  content: string,
  label?: string,
  avatar: React.ReactElement,
}

const assetBase = 'https://assets.bizwise.com/landing-ui/testimonials';
const defaultTestimonials: Testimonial[] = [
  {
    name: 'Michael Branson',
    label: 'Platinum Networks',
    content: 'Excellent firm to deal with.  Very responsive with great technical knowledge.',
    avatar: <StaticImage src={`${assetBase}/4.jpg`} alt="Michael Branson" width={avatarSizePx} />,
  },
  {
    name: 'Donna Dong',
    label: 'Bothwin Capital',
    content: `I recently started an investment company and needed to get my online presence sorted ASAP. That's when I found Bizwise. These guys were incredible! They set up a stunning website for me, handling everything from design to content. I didn’t have to stress over any details.

    The whole process was super smooth, and now I have a cool website that's already pulling in clients. Totally recommend Bizwise if you want to get your site up and running without any headaches!
    
    Big thanks to the Bizwise team!`,
    avatar: <StaticImage src={`${assetBase}/7.jpg`} alt="Donna Dong" width={avatarSizePx} />,
  },
  {
    name: 'Manisha Sharma',
    content: 'I currently use Bizwise to manage my fine art business. Service has been great & they make it very easy to manage my website & business. I can upload new artwork very easily in the Website Editor tool & sell art from my site.',
    avatar: <StaticImage src={`${assetBase}/2.jpg`} alt="Manisha Sharma" width={avatarSizePx} />,
  },
];

interface Props {
  className?: string,
  centered?: boolean,
  isLight: boolean,
  testimonials?: Testimonial[],
}

const Testimonials: React.FC<Props> = ({
  className,
  centered,
  isLight,
  testimonials,
}) => {
  const theme: Theme = useTheme();
  const quoteColor = isLight ? 'blackish' : 'white';
  const nameColor = isLight ? 'textPrimary' : 'white';
  const labelColor = isLight ? 'textSecondary' : 'white';
  const inactiveIndexColor = isLight
    ? theme.palette.secondary.GRAY // theme.palette.text.BLACKISH
    : theme.palette.primary.WHITE;

  const classes = useStyles({ isLight });
  const [activeIdx, setActiveIdx] = React.useState(0);
  const data = testimonials ?? defaultTestimonials;
  const {
    name, label, avatar,
  } = data[activeIdx];
  return (
    <div className={className}>
      <div style={{ display: 'flex' }}>
        <Carousel
          activeIdx={activeIdx}
          setActiveIdx={setActiveIdx}
          isLight={isLight}
        >
          {data.map(({ name, content }) => (
            <div
              className={classes.quoteContainer}
              key={name}
              style={{
                maxWidth: centered ? pxToRem(800) : undefined,
              }}
            >
              <img
                src={assetLink('BizwiseQuoteIcon.svg')}
                alt=""
                style={{
                  width: pxToRem(30),
                  height: pxToRem(30),
                  marginRight: spacing(7.5),
                }}
              />
              <Typography
                variant="h3"
                color={quoteColor}
                style={{
                  textAlign: centered ? 'center' : 'left',
                }}
              >
                {content}
              </Typography>
              <img
                src={assetLink('BizwiseQuoteIcon.svg')}
                alt=""
                style={{
                  width: pxToRem(30),
                  height: pxToRem(30),
                  marginLeft: spacing(7.5),
                  marginTop: 'auto',
                }}
                className={classes.endQuote}
              />
            </div>
          ))}
        </Carousel>
      </div>
      <div
        className="mt-2 desktop:mt-7"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: centered ? 'center' : 'left',
          // Line it up with the quote text
          marginLeft: centered ? 0 : pxToRem(58),
        }}
      >
        {React.cloneElement(avatar, {
          className: classes.avatar,
        })}
        <div>
          <Typography variant="p2" color={nameColor} style={{ fontWeight: 800 }}>
            {name}
          </Typography>
          <Typography variant="p3" color={labelColor} style={{ fontWeight: 400 }}>
            {label || ' '}
          </Typography>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          marginTop: pxToRem(30),
          justifyContent: centered ? 'center' : 'left',
          // Line it up with the quote text
          marginLeft: centered ? 0 : pxToRem(58),
        }}
      >
        {Array.from({ length: data.length }, (_, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <IconButton onClick={() => setActiveIdx(i)} key={i}>
            <div
              style={{
                width: 9,
                height: 9,
                borderRadius: '50%',
                backgroundColor: activeIdx === i
                  ? theme.palette.primary.TEAL
                  : inactiveIndexColor,
              }}
            />
          </IconButton>
        ))}
      </div>
    </div>
  );
};

export default Testimonials;
