/* eslint-disable react/no-array-index-key */
import cx from 'clsx';
import React, { CSSProperties } from 'react';
import { createUseStyles } from 'react-jss';
import Typography from 'src/components/core/Typography';
import SectionContainer from 'src/sections/shared/SectionContainer';
import theme, { breakpoint, pxToRem, spacing } from 'src/theme';
import { assetLink } from 'src/utils';
import useResizeObserver from 'use-resize-observer';

const useStyles = createUseStyles({
  title: {
    maxWidth: pxToRem(870),
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  underline: {
    marginBottom: spacing(10),
    [breakpoint.down('md')]: {
      marginBottom: spacing(8),
    },
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      position: 'relative',
      width: '50%',
      [breakpoint.down('md')]: {
        width: '100% !important',
      },
    },
  },
  row1: {
    [breakpoint.down('md')]: {
      flexDirection: 'column',
    },
  },
  row2: {
    [breakpoint.down('md')]: {
      flexDirection: 'column-reverse',
    },
  },
  textContent1: {
    marginLeft: '5%',
    maxWidth: pxToRem(622),
    paddingLeft: pxToRem(20),
    [breakpoint.down('md')]: {
      margin: `${spacing(8)} 0`,
      paddingLeft: 0,
    },
    [breakpoint.down('md')]: {
      margin: `${spacing(14)} 0 ${spacing(8)} 0`,
    },
  },
  textContent2: {
    maxWidth: pxToRem(622),
    paddingRight: pxToRem(20),
    [breakpoint.down('md')]: {
      margin: `${spacing(10)} 0`,
      paddingRight: 0,
    },
    marginLeft: 'auto',
    [breakpoint.down('md')]: {
      marginLeft: 0,
    },
  },
  presentation: {
    height: 'calc(50vw * 0.6)',
    width: '50%',
    maxWidth: pxToRem(600),
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    [breakpoint.down('md')]: {
      height: 'calc(100vw * 0.7)',
    },
  },
  rectangle: {
    borderRadius: 20,
    width: '87%',
    height: 0,
    paddingBottom: '65%',
    transform: 'rotate(119deg)',
    position: 'absolute',
    right: 0,
  },
  imgContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: `${pxToRem(120)} 0`,
    [breakpoint.down('sm')]: {
      padding: `${pxToRem(80)} 0`,
    },
  },
});

type Content = {
  title: string,
  checklist: React.ReactNode[],
  media: React.ReactElement,
}

interface Props {
  title?: string,
  rightContent: Content,
  leftContent?: Content,
  leftShapeColorOverride?: string,
  rightShapeColorOverride?: string,
  excludeTopPadding?: boolean,
  sectionContainerStyleOverrides?: CSSProperties,
  contentAlign?: CSSProperties['alignItems'],
}

const RightLeftChecklistSection: React.FC<Props> = ({
  title,
  rightContent,
  leftContent,
  leftShapeColorOverride,
  rightShapeColorOverride,
  excludeTopPadding,
  sectionContainerStyleOverrides,
  contentAlign = 'center',
}) => {
  const classes = useStyles();
  const {
    height: firstMediaHeight = '70%',
    ref: firstMediaRef,
  } = useResizeObserver<HTMLDivElement>();
  const eclipseHeight = typeof firstMediaHeight === 'string'
    ? firstMediaHeight
    : (`calc(${pxToRem(firstMediaHeight)} + ${pxToRem(100)})`);
  return (
    <SectionContainer
      style={{
        overflow: 'visible',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        ...sectionContainerStyleOverrides,
      }}
    >
      {title ? (
        <Typography variant="h2" color="blue" underline centered className={classes.title} underlineClassName={classes.underline}>
          {title}
        </Typography>
      ) : (
        <div style={{ marginBottom: excludeTopPadding ? 0 : pxToRem(50) }} />
      )}
      <div className={cx(classes.row, classes.row1)}>
        <div className={classes.presentation}>
          <div style={{
            position: 'absolute',
            backgroundColor: rightShapeColorOverride ?? '#FEB471',
            borderRadius: '50%',
            // This is a trick keep the height equal to the width
            width: eclipseHeight,
            height: 0,
            paddingBottom: eclipseHeight,
          }}
          />
          <div ref={firstMediaRef}>
            {rightContent.media}
          </div>
        </div>
        <div className={classes.textContent1}>
          <Typography variant="h3" color="blue" style={{ marginBottom: spacing(4) }}>
            {rightContent.title}
          </Typography>
          {rightContent.checklist.map((content, idx) => (
            <div style={{ display: 'flex', alignItems: contentAlign, marginBottom: spacing(2) }} key={`rightContent-${idx}`}>
              <img src={assetLink('CheckIcon.svg')} alt="Check Icon" style={{ marginRight: spacing(6) }} />
              <Typography variant="p2" color="blackish">
                {content}
              </Typography>
            </div>
          ))}
        </div>
      </div>
      {leftContent && (
        <div className={cx(classes.row, classes.row2)}>
          <div className={classes.textContent2}>
            <Typography variant="h3" color="blue" style={{ marginTop: spacing(11), marginBottom: spacing(4) }}>
              {leftContent.title}
            </Typography>
            {leftContent.checklist.map((content, idx) => (
              <div style={{ display: 'flex', alignItems: contentAlign, marginBottom: spacing(2) }} key={`leftContent-${idx}`}>
                <img src={assetLink('CheckIcon.svg')} alt="Check Icon" style={{ marginRight: spacing(6) }} />
                <Typography variant="p2" color="blackish">
                  {content}
                </Typography>
              </div>
            ))}
          </div>
          <div className={classes.imgContainer}>
            <div style={{ position: 'relative', maxWidth: pxToRem(600) }}>
              <div
                style={{
                  backgroundColor: leftShapeColorOverride ?? theme.palette.primary.TEAL,
                }}
                className={classes.rectangle}
              />
              {leftContent.media}
            </div>
          </div>
        </div>
      )}
    </SectionContainer>
  );
};

export default RightLeftChecklistSection;
