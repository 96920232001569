import React from 'react';
import { createUseStyles } from 'react-jss';
import {
  spacing, breakpoint, Theme, pxToRem,
} from 'src/theme';
import SectionContainer from 'src/sections/shared/SectionContainer';
import SlantedSection from 'src/sections/shared/SlantedSection';
import Typography from 'src/components/core/Typography';
import Testimonials, { Testimonial } from 'src/components/Testimonials';
import Button from 'src/components/core/Button';
import { SectionImages } from './StaticImages';

interface StyleProps {
  isLight: boolean,
}

const useStyles = createUseStyles<string, StyleProps, Theme>((theme) => ({
  content: {
    maxWidth: '100%',
    padding: `${spacing(15)} ${spacing(10)}`,
    [breakpoint.down('md')]: {
      padding: spacing(5),
    },
    [breakpoint.down('sm')]: {
      padding: `${spacing(5)} ${spacing(2)}`,
    },
  },
  text: {
    [breakpoint.down('md')]: {
      textAlign: 'center',
    },
  },
  underline: {
    marginBottom: spacing(10),
    [breakpoint.down('md')]: {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  slantedSectionContentBackground: {
    background: theme.palette.secondary.LIGHT_BLUE,
  },
  slantedSectionContainer: ({ isLight }) => ({
    background: isLight
      ? theme.palette.secondary.LIGHT_BLUE
      : 'linear-gradient(109deg, #13266D 0.49%, #00B6B6 130.79%), #F7F7F7',
  }),
  sectionContainer: ({ isLight }) => ({
    background: isLight
      ? theme.palette.secondary.LIGHT_BLUE
      : 'linear-gradient(109deg, #13266D 0.49%, #00B6B6 130.79%), #F7F7F7',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }),
  testimonialContainer: {
    maxWidth: pxToRem(1200),
  },
}));

interface Props {
  testimonials?: Testimonial[];
  isLight: boolean,
  showBackgroundImage: boolean,
}

const TestimonialsSection: React.FC<Props> = ({
  testimonials,
  isLight,
  showBackgroundImage,
}) => {
  const classes = useStyles({ isLight });
  const testimonialComponent = (
    <Testimonials
      testimonials={testimonials}
      className={!showBackgroundImage ? classes.testimonialContainer : undefined}
      centered={!showBackgroundImage}
      isLight={isLight}
    />
  );
  if (showBackgroundImage) {
    return (
      <SlantedSection
        img={SectionImages[1]}
        imgLeft
        slantAmount={0.1}
        contentWidth={0.7}
        className={classes.slantedSectionContainer}
        contentClassName={classes.slantedSectionContentBackground}
      >
        <div className={classes.content}>
          <Typography
            variant="h2"
            color={isLight ? 'blue' : 'white'}
            underline
            className={classes.text}
            underlineClassName={classes.underline}
          >
            Hear from Bizwise businesses
          </Typography>
          {testimonialComponent}
          <Button
            className="mt-6 w-full md:w-auto ml-0 md:ml-[58px]"
            href="/customers/"
            color="teal"
            variant="filled"
          >
            Meet our customers
          </Button>
        </div>
      </SlantedSection>
    );
  }
  return (
    <SectionContainer className={classes.sectionContainer}>
      <Typography
        variant="h2"
        color={isLight ? 'blue' : 'white'}
        underline
        underlineClassName={classes.underline}
        centered
      >
        Testimonials
      </Typography>
      {testimonialComponent}
    </SectionContainer>
  );
};

export default TestimonialsSection;
