import React from 'react';
import { createUseStyles } from 'react-jss';
import { spacing, breakpoint, pxToRem } from 'src/theme';
import { assetLink } from 'src/utils';
import SectionWithVideoOverlap from 'src/sections/shared/SectionWithVideoOverlap';
import Typography from 'src/components/core/Typography';

const useStyles = createUseStyles({
  container: {
    background: '#F7F7F7',
  },
  grid: {
    display: 'grid',
    justifyContent: 'center',
    columnGap: spacing(10),
    rowGap: spacing(10),
    marginTop: spacing(15),
    gridTemplateColumns: `repeat(3, minmax(0, ${pxToRem(420)}))`,
    [breakpoint.down('sm')]: {
      gridTemplateColumns: 'repeat(1, minmax(0, 1fr))',
      marginTop: spacing(10),
    },
  },
  icon: {
    height: pxToRem(60),
  },
});

const content: {
  iconSrc: string,
  label: string,
  details: (businessType?: string) => string,
}[] = [
  {
    iconSrc: assetLink('landing-ui/icons/MarketingIcon.svg'),
    label: 'Marketing Machine',
    details: () => 'We build what your customers need. Lighting fast loading speeds, and ready to dominate local search result.',
  },
  {
    iconSrc: assetLink('landing-ui/icons/DesignsIcon.svg'),
    label: 'Perfect Designs',
    details: (businessType) => `We'll craft your ${
      businessType ? `${businessType} ` : ''
    }website, accounting for your size and features you will need. Optimized for all devices, and browsers.`,
  },
  {
    iconSrc: assetLink('landing-ui/icons/EasyIcon.svg'),
    label: 'Easy to Use',
    details: () => 'Bizwise handles everything for you from set up, to design, to copywriting, to launch.',
  },
];

interface Props {
  businessType?: string,
}

const RunYourBusiness: React.FC<Props> = ({ businessType }) => {
  const classes = useStyles();
  return (
    <SectionWithVideoOverlap className={classes.container}>
      <div className={classes.grid}>
        {content.map(({ iconSrc, label, details }) => (
          <div className="flex flex-col items-center" key={label}>
            <img src={iconSrc} alt={`${label} Icon`} className={classes.icon} />
            <Typography variant="h3" color="blue" centered style={{ marginTop: spacing(6) }}>
              {label}
            </Typography>
            <Typography variant="p1" color="blue" centered style={{ marginTop: spacing(2) }}>
              {details(businessType)}
            </Typography>
          </div>
        ))}
      </div>
    </SectionWithVideoOverlap>
  );
};

export default RunYourBusiness;
