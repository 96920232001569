import React from 'react';
import { createUseStyles } from 'react-jss';
import { breakpoint, spacing } from 'src/theme';
import SectionContainer from 'src/sections/shared/SectionContainer';
import Button from 'src/components/core/Button';
import Typography from 'src/components/core/Typography';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  button: {
    marginTop: spacing(10),
    [breakpoint.down('sm')]: {
      width: '100%',
    },
  },
});

const Revolutionize: React.FC = () => {
  const classes = useStyles();
  return (
    <SectionContainer
      className={classes.container}
      backgroundShape={4}
      background="light-blue"
      backgroundShapeBlendMode="soft-light"
    >
      <Typography variant="h2" color="blue" centered>
        Let&apos;s revolutionize your
        <br />
        online presence
      </Typography>
      <Button
        variant="filled"
        color="teal"
        href="/register/"
        className={classes.button}
      >
        Start now
      </Button>
    </SectionContainer>
  );
};

export default Revolutionize;
