import React, { useState } from 'react';
import a from 'indefinite';
import cx from 'clsx';
import { createUseStyles } from 'react-jss';
import { pxToRem, breakpoint, spacing } from 'src/theme';
import { useSectionAboveStyles } from 'src/sections/shared/SectionWithVideoOverlap';
import SlantedSection from 'src/sections/shared/SlantedSection';
import Typography from 'src/components/core/Typography';
import Button from 'src/components/core/Button';
import NavbarSpacer from 'src/components/Navbar/Spacer';
import { assetLink } from 'src/utils';
import { SectionImages } from '../shared/StaticImages';

const useStyles = createUseStyles({
  container: {
    height: pxToRem(650),
    minHeight: pxToRem(650),
    [breakpoint.down('md')]: {
      height: 'auto',
      minHeight: 'auto',
    },
  },
  content: {
    marginLeft: 'auto',
    paddingLeft: spacing(6),
    maxWidth: pxToRem(580),
    [breakpoint.down('md')]: {
      paddingRight: spacing(6),
      paddingTop: spacing(2),
      paddingBottom: pxToRem(200),
      marginLeft: 0,
    },
    [breakpoint.down('sm')]: {
      paddingBottom: pxToRem(60),
    },
    marginBottom: pxToRem(40),
  },
  contentBackground: {
    background: 'radial-gradient(153.68% 510.76% at 153.68% 79.94%, #3D78B8 0%, #13266D 92.07%), #13296B',
    [breakpoint.down('sm')]: {
      background: 'linear-gradient(107.95deg, #13266D -12.75%, #5FBCFF 253.76%), #13296B',
    },
  },
  text: {
    marginTop: spacing(4),
    maxWidth: pxToRem(570),
    [breakpoint.down('sm')]: {
      width: '100%',
    },
  },
  header: {
    textTransform: 'capitalize',
  },
});

interface Props {
  businessType: string;
}

const Hero: React.FC<Props> = ({ businessType }) => {
  const classes = useStyles();
  const videoPaddingClasses = useSectionAboveStyles();

  const formattedBusinessType = businessType.toUpperCase().replace(/\//g, '');
  const folderPath = `stock-photos/${formattedBusinessType}`;

  const [imgError, setImgError] = useState(false);
  const img = (businessType && !imgError) ? (
    <img
      src={assetLink(`${folderPath}/hero.jpg`)}
      alt="Hero"
      onError={() => setImgError(true)}
    />
  ) : SectionImages[6];

  return (
    <SlantedSection
      img={img}
      contentWidth={0.6}
      slantAmount={0.25}
      className={classes.container}
      contentClassName={classes.contentBackground}
      showNavbarGradient
    >
      <div className={cx(classes.content, videoPaddingClasses.root)}>
        <NavbarSpacer className="hidden md:block" />
        <Typography variant="h2" color="white" className={classes.header}>
          {businessType ? a(businessType) : 'A'}
          {' '}
          Website That Works As Hard As You Do
        </Typography>
        <Typography variant="p1" color="white" className={classes.text}>
          We&apos;re here to craft &amp; launch your
          {' '}
          {businessType ? `${businessType} ` : ''}
          business&apos; new web presence.
        </Typography>
        <Button href="/register/" variant="filled" color="teal" style={{ marginTop: spacing(8), minWidth: pxToRem(200) }}>
          Claim Your Free Website
        </Button>
        <Typography variant="p3" color="white" style={{ marginTop: spacing(4), marginBottom: spacing(4) }}>
          No credit card required.
        </Typography>
      </div>
    </SlantedSection>
  );
};

export default Hero;
