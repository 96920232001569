import React from 'react';
import { createUseStyles } from 'react-jss';
import { breakpoint } from 'src/theme';
import WebEditorVideo from 'src/components/WebEditorVideo';
import { StaticImage } from 'gatsby-plugin-image';
import RightLeftChecklistSection from '../shared/RightLeftChecklist';

const useStyles = createUseStyles({
  video: {
    width: '85%',
    [breakpoint.down('md')]: {
      width: '100%',
    },
  },
});

const content1 = [
  'Beautifully designed',
  'Optimized for all devices',
  'Dominate with local Search Engine Optimization',
  'Lightning fast speeds',
  'Customized just for you and your business',
];
const content2 = [
  'We\'re here every step of the way, everyday.',
  'Our website editing service is at your disposal.',
  'As the tech world updates, so does your website.',
];

interface Props {
  businessType?: string,
}

const BuiltSection: React.FC<Props> = ({ businessType }) => {
  const classes = useStyles();
  // TODO: Instead of showing the WebEditorVideo,
  // show a snapshot of the website for their business type
  // TODO: Change "Your Customers" based on business type (e.g. "Your Clients")
  return (
    <RightLeftChecklistSection
      title={`Built with Your ${
        businessType ? `${businessType} ` : ''
      } Business and Your Customers in Mind`}
      rightContent={{
        title: 'A Bizwise built website beats out the competition. Here\'s why:',
        checklist: content1,
        media: (
          <WebEditorVideo className={classes.video} />
        ),
      }}
      leftContent={{
        title: 'We stay ahead of technology trends, so you don\'t have to',
        checklist: content2,
        media: (
          <StaticImage
            width={600}
            src="https://assets.bizwise.com/landing-ui/sections/9.jpg"
            alt="Programmer woman at computer"
            style={{
              borderRadius: 20,
              filter: 'drop-shadow(0px 25px 50px rgba(0, 0, 0, 0.25))',
            }}
          />
        ),
      }}
    />
  );
};

export default BuiltSection;
