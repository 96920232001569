import React, { useState, useEffect, useRef } from 'react';
import { useIsVisible } from 'src/hooks';
import { assetLink } from 'src/utils';
import LaptopContainer from 'src/images/video-containers/BlackMac.svg';
import PhoneContainer from 'src/images/video-containers/Phone.svg';

interface Props {
  style?: React.CSSProperties,
  className?: string,
}

const WebEditorVideo: React.FC<Props> = ({ style, className }) => {
  const [macVideoReady, setMacVideoReady] = useState(false);
  const [macVideoEnded, setMacVideoEnded] = useState(false);
  const [iphoneVideoReady, setIphoneVideoReady] = useState(false);
  const [iphoneVideoEnded, setIphoneVideoEnded] = useState(false);
  const macVideoRef = useRef<HTMLVideoElement>(null);
  const iphoneVideoRef = useRef<HTMLVideoElement>(null);
  const isVisible = useIsVisible(macVideoRef);

  // iOS workaround to force the video to load
  useEffect(() => {
    macVideoRef.current?.load();
  }, [macVideoRef]);
  useEffect(() => {
    iphoneVideoRef.current?.load();
  }, [iphoneVideoRef]);

  useEffect(() => {
    if (macVideoRef.current && macVideoRef.current.readyState > 2) {
      setMacVideoReady(true);
    }
    if (iphoneVideoRef.current && iphoneVideoRef.current.readyState > 2) {
      setIphoneVideoReady(true);
    }
  }, []);

  useEffect(() => {
    if (isVisible && macVideoReady && iphoneVideoReady
      && macVideoRef.current && iphoneVideoRef.current) {
      macVideoRef.current.play();
      iphoneVideoRef.current.play();
    }
  }, [isVisible, macVideoReady, iphoneVideoReady]);

  useEffect(() => {
    if (iphoneVideoEnded && macVideoEnded) {
      setIphoneVideoEnded(false);
      setMacVideoEnded(false);
      iphoneVideoRef.current?.play();
      macVideoRef.current?.play();
    }
  }, [iphoneVideoEnded, macVideoEnded]);

  return (
    <div
      style={{
        position: 'relative',
        ...style,
      }}
      className={className}
    >
      <div style={{ position: 'relative' }}>
        <video
          controls={false}
          disableRemotePlayback
          ref={macVideoRef}
          src={assetLink('landing-ui/videos/WebEditorDesktopSmall.mp4')}
          poster={assetLink('landing-ui/videos/WebEditorDesktopSmall.mp4.thumbnail.jpg')}
          muted
          playsInline
          onCanPlayThrough={() => setMacVideoReady(true)}
          onEnded={() => setMacVideoEnded(true)}
          style={{
            position: 'absolute',
            top: '4%',
            left: '15%',
            height: '83%',
          }}
        />
        <LaptopContainer style={{ width: '100%', height: '100%' }} />
      </div>
      <div
        style={{
          position: 'absolute',
          width: '20%',
          right: '-2%',
          bottom: '-4%',
        }}
      >
        <video
          controls={false}
          disableRemotePlayback
          ref={iphoneVideoRef}
          src={assetLink('landing-ui/videos/WebEditorMobileSmall.mp4')}
          poster={assetLink('landing-ui/videos/WebEditorMobileSmall.mp4.thumbnail.jpg')}
          muted
          playsInline
          onCanPlayThrough={() => setIphoneVideoReady(true)}
          onEnded={() => setIphoneVideoEnded(true)}
          style={{
            position: 'absolute',
            top: '8%',
            left: '10%',
            height: '83%',
          }}
        />
        <PhoneContainer style={{ width: '100%', height: '100%' }} />
      </div>
    </div>
  );
};

export default WebEditorVideo;
